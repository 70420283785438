.NavtopInner{
    background-color: #ECF0F5;
    position: fixed;
    z-index: 5;
    z-index: 20;
    width: 100%;
    background: #4c4184;
    color: #333;
    padding-top: .4rem;
    padding-bottom: .4rem;
    padding-right: 1rem;
    padding-left: 1rem;
    background-color: #fff;
    border-bottom: 1px solid #e8e8e8;
}

.NavtopInner button{
    background-color: transparent !important;
    box-shadow: none !important;
    outline: 0 !important;
    border: 0 !important;
}

.NavtopInner button svg{
    font-size: 1.4rem;
}