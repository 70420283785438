#Footer{
    background-color: #333333;
    color: rgb(255 255 255 / 80%);
    width: 100%;
    font-size: .8rem;
    order: 2;
    font-weight: 300;
}

#Footer a{
    color: rgb(255 255 255 / 80%);
    text-decoration: none;
    border-right: 1px solid #ccc;
    padding: 0 10px;
}

#Footer a:last-child {
    border-right: none;
}