.App-header{
  display: flex;
}

#login-start {
  width: 70%;
  background-color: #f8f8f8;
  min-height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  order: 1;
  overflow: hidden;
  position: relative;
}

#login-end {
  /* order: 1; */
  background-image: url('../../assets/images/foto.jpg');
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  width: 30%;
  /* width: 400px; */
  /* float: none !important; */
  padding: 0;
  position: relative;
}

@media (max-width: 992px) {
  .App-header {
    flex-direction: column;
  }

  #login-start {
    width: 100%;
    height: auto;
    min-height: auto;
  }

  #login-end {
    width: 100%;
    order: 1;
    height: auto;
    min-height: auto;
  }
}

.owl-stage {
  display: flex;
}

.owl-item {
  margin: 0;
  padding: 0;
}

#logo-login{
  width: 170px;
  position: absolute;
  left: 1rem;
  top: 1.5rem;
}

#login-end::after{
  content: '';
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 0;
  left: 0;
}

/* .login-end-layer{
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
} */

@media (min-width: 992px) {

  .App-header #Footer{
    position: absolute;
    bottom: 0;
  }  

}

.card-test-info{
  margin: 1rem;
  background-color: rgba(255, 255, 255, 0.87);
  z-index: 2;
  position: relative;
  border-radius: 5px;
  font-size: .9rem;
  padding: 1.3rem;
}

.card-test-info a{
  color: var(--bg-primary);
}

.card-test-info-2{
  margin: 1rem;
  z-index: 2;
  position: relative;
  border-radius: 5px;
  color: #fff;
  font-size: .9rem;
}
