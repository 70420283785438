.NavStartInner{
    width: 225px;
    /* background-color: #ECF0F5; */
    height: 100vh;
    padding-top: 3.5rem;
    transition: .3s all ease;
    /* background-color: #212529; */
    background-color: #fff;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%), 0 2px 1px -1px rgb(0 0 0 / 12%), 0 1px 1px 0 rgb(0 0 0 / 14%);
}

#logo-inner{
    max-width: 180px;
    display: block;
    /* padding-top: .3rem;
    padding-bottom: .3rem; */
    /* filter: brightness(0) invert(1); */
    /* width: 40px; */
    height: 35px;
}

.NavStartInner .nav-link{
    font-weight: 400;
    font-size: 1rem;
    /* padding-left: 1.5rem; */
    padding-top: .7rem;
    padding-bottom: .7rem;
    border-radius: 0 .25rem .25rem 0;
    color: rgba(0, 0, 0, 0.7);
    border-left: 5px solid #fff;

}

.NavStartInner .nav-link:hover{
    color: inherit;
    /* background-color: #e5e8ed; */
    color: #000;

}

.NavStartInner .nav-link.active{
    /* border-left: 5px solid #4C4184;
    color: #4C4184;*/
    color: #000;
    border-left: 5px solid var(--bg-primary);
    background-color: rgba(var(--bg-primary-rgb), 0.05);
    /* box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important; */
    /* background-color: #4C4184;
    color: #4C4184;
    background-color: #fff;
    color: #4c4184;
    background-color: #4c41841c; */
}

.NavStartInner .nav-link.active svg{
    color: var(--bg-primary);
}

.NavStartInner footer{
    /* color: rgba(255, 255, 255, 0.6); */
}

.NavStartInner footer ul{
    list-style: none;
    padding: 0;
    margin: 0;
}

.NavStartInner footer ul a{
    color: rgba(255, 255, 255, 0.6);
    
    text-decoration: none;
}

.NavStartInner small{
    font-size: .7rem;
}

.NavStartInner h4{
    font-weight: bolder;
    /* padding-left: 1.5rem; */
    text-align: center;
}

@media (max-width: 768px) {

    .NavStartInner{
        position: absolute;
    padding-top: 2.5rem;
    left: -100%;
        z-index: 11;
        box-shadow: 0 8px 17px 2px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2) !important;
    }

}