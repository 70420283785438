/* Config */

:root {
  --bg-primary: #c40101;
  --bg-primary-rgb: 196, 1, 1;
  --bg-primary-dark: #b30101;
  --color-dark: #22242a;
  --color-light-grey: #aeb3b7;
  --color-grey: #f4f4f4;
  --light: rgb(210 219 233);
  --bg-neutral: #ffffff;
  --text-secondary: #6f7178;
  --light-20: rgba(225, 231, 240, 0.2);
  --success: #28a745;
  --border-radius-box: 5px;
}

/* Imports Fonts */

@font-face {
  font-family: Aeonik;
  src: url('./assets/fonts/Aeonik-Air.otf') format('opentype');
  font-weight: air;
}

@font-face {
  font-family: Aeonik;
  src: url('./assets/fonts/Aeonik-Thin.otf') format('opentype');
  font-weight: 100;
}

@font-face {
  font-family: Aeonik;
  src: url('./assets/fonts/Aeonik-Light.otf') format('opentype');
  font-weight: 300;
}

@font-face {
  font-family: Aeonik;
  src: url('./assets/fonts/Aeonik-Regular.otf') format('opentype');
  font-weight: 400;
}

@font-face {
  font-family: Aeonik;
  src: url('./assets/fonts/Aeonik-Medium.otf') format('opentype');
  font-weight: 500;
}

@font-face {
  font-family: Aeonik;
  src: url('./assets/fonts/Aeonik-Bold.otf') format('opentype');
  font-weight: 700;
}

@font-face {
  font-family: Aeonik;
  src: url('./assets/fonts/Aeonik-Black.otf') format('opentype');
  font-weight: 900;
}

body{
  font-family: Aeonik, sans-serif;
  background-color: #f2f4f6;
}


/* Overwrite bootstrap */

.btn-primary {
  background-color: var(--bg-primary) !important;
  border-color: var(--bg-primary) !important;
}

.btn-primary:focus{
  background-color: var(--bg-primary);
  border-color: var(--bg-primary);
}

.btn-primary.disabled, .btn-primary:disabled{
  background-color: var(--bg-primary);
  border-color: var(--bg-primary);
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled{
  color: var(--bg-primary);
}

.btn:focus{
  box-shadow: none !important;
  outline: 0;
}

.btn-primary:hover {
  background-color: rgb(var(--bg-primary-rgb) , 0.7) !important;
}

.btn-info{
  background-color: #3a78b9 !important;
  border-color: #3a78b9 !important;
  color: #FFF !important;
}

.btn-danger {
  color: #fff !important;
  background-color: #ca515d !important;
  border-color: #ca515d !important;
}

.btn-transparent{
  color: var(--text-secondary);
  background-color: transparent;
  border: 0;
  line-height: 0;
}

.btn-default {
  background-color: rgb(239, 239, 239);
  color: rgb(59, 59, 59);
}

.btn {
  border-radius: var(--border-radius-box);
}

.form-check-input:checked{
  background-color: var(--bg-primary);
  border-color: var(--bg-primary);
}

.form-check-input:focus {
  border-color: var(--bg-primary);
  box-shadow: 0 0 0 0.25rem rgb(var(--bg-primary-rgb) , 0.25);
}

.color-primary {
  color: var(--bg-primary);
}

.shadow-lg {
  /* box-shadow: 0 1rem 3rem rgba(0,0,0,.175)!important; */
  /* box-shadow: 0 24px 38px 3px rgba(0,0,0,0.14), 0 9px 46px 8px rgba(0,0,0,0.12), 0 11px 15px -7px rgba(0,0,0,0.2) !important; */
  box-shadow: 0 8px 17px 2px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2) !important;
}

.page-link {
  color: #000;
}

.page-item.active .page-link {
  background-color: var(--bg-primary);
  border-color: var(--bg-primary);
}

.btn-outline-primary {
  color: var(--bg-primary);
  border-color: var(--bg-primary);
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: var(--bg-primary);
  border-color: var(--bg-primary);
}

.form-control{
  border-radius: var(--border-radius-box);
}

.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: var(--color-dark);
  outline: 0;
  box-shadow: none !important;
}

.form-select:focus {
  color: #212529;
  background-color: #fff;
  border-color: var(--color-dark);
  outline: 0;
  box-shadow: none !important;
}

.table-hover>tbody>tr:hover{
  --bs-table-accent-bg: rgb(0 0 0 / 4%);
}

/* .table thead tr{
  background-color: #f2f2f2;
} */

.fw-500 {
  font-weight: 500;
}

/* Animations */

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 4rem, 0);
    transform: translate3d(0, 4rem, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 4rem, 0);
    transform: translate3d(0, 4rem, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

/* Pages */

.Side-end {
  width: calc(100% - 225px);
  float: right;
  padding-top: 3.5rem;
  background-color: #f2f4f6;
  /* top: 3rem; */
  /* position: relative; */
  padding-left: 0;
  padding-right: 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.Column-small {
  border-radius: 0.25rem;
  background-color: #fff;
  padding: 1.2rem;
  height: auto;
  align-self: self-start;
  top: 5rem;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.MainInner{
  border-radius: 0.25rem 0.25rem 0 0;
  background-color: #FFF;
  padding: 1.2rem !important;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.bg-opacity-primary{
  background: #ecf0f5;
}

@media (max-width: 1200px) {

  .Column-small{
    width: 100% !important;
    position: static !important;
    z-index: 1;
    margin-bottom: 1.5rem;
  }

}

@media (max-width: 768px) {

  .Side-end{
    width: 100% !important;
    /* padding-left: .8rem;
    padding-right: .8rem; */
  }

  .Column-small{
    padding: 1rem;
  }

  .MainInner{
    padding: 1rem !important;
  }

}

.container-inner{
  background-color: #f2f4f6;
}

.Breadcrumb-inner{
  font-size: 1.1rem;
  font-weight: 700;
}

.Breadcrumb-inner a{
  color: #000;
  text-decoration: none;
}

#icon-bars{
  cursor: pointer;
}

.toggle-menu{
  left: 0;
}

#fade-menu{
  width: 0%;
  position: fixed;
  min-height: 100vh;
  background-color: transparent;
  z-index: 7;
  transition: .3s background ease;
}

.toggle-fade-menu{
  width: 100% !important;
  background-color: #0006 !important;
}

.table-fixed-end > tbody > tr > td:last-child,
.table-fixed-end > thead > tr > th:last-child
{
  position: sticky;
  background: #f5f5f5;
  right: 0;
  white-space: nowrap;
  box-shadow: inset 1px 0 #ddd;
}

.table-fixed-end > thead > tr > th:last-child{
  background: #f5f5f5;
  box-shadow: inset 1px 0 #ddd;

}

.table-orderby-active{
  color: #3a78b9;
  font-weight: 900;
}

.form-select:disabled {
  /* background-color: #efefef; */
}

.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

/* Leyenda */

.leyenda td{
  background-color: #f9fafc;
  box-shadow: none;
}

.item-leyenda svg{
  font-size: .6em !important;
}

.item-leyenda{
  margin-right: 2em; 
  display: flex; 
  align-items: center;
  color: var(--text-secondary) !important;
}

.tableMain {
  border: 1px solid var(--light);
}

.tableMain > thead > tr > th, 
.tableMain > tbody > tr > td {
  border-top: none;
  font-size: 14px;
  line-height: 1.43rem;
  padding: 16px 14px;
}

.table>:not(:last-child)>:last-child>*{
  border-bottom-color: #dee2e6;
}

.table-bordered>:not(caption)>*>*{
  border-width: 0;
}

.table tbody tr.tr-firmado {
  background-color: #28a7450f;
}

.tableMain > tbody > tr:hover {
  background-color: var(--light-20);
  --bs-table-accent-bg: var(--light-20);
}

/* Modal ver mas */

.btn-modalVermas{
  cursor: pointer;
  color: var(--text-secondary);
  transition: all ease .3s;
  font-size: 1.4rem;
}

.btn-modalVermas:hover{
  color: #333;
}

.shrink-td {
  width: 0.1%;
  white-space: nowrap;
}

@media (min-width: 992px){

  .modal-lg, .modal-xl {
    max-width: 900px;
  }
}

#result-document {
  background-color: #fff;
  min-height: 300px;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

#result-document-image {
  border: 1px solid #C4C4C4;
  box-shadow: 1px 3px 6px #ddd;
  position: relative;
  z-index: 20;
}

#download-file {
  position: relative;
  z-index: 20;
}


/* Modal ver mas */

.btn-modalVermas{
  cursor: pointer;
  color: var(--text-secondary);
  transition: all ease .3s;
}

.btn-modalVermas:hover{
  color: #333;
}

#tab-vermas .nav,
#tab-vermas .nav li{
  position: relative;
  z-index: 10;
}

#tab-vermas .nav-tabs{
  border-bottom: 0;
}

#tab-vermas .nav-link {
  position: relative;
  z-index: 5;
  color: #8a8f94;
}

@media (min-width: 992px) { 

  #tab-vermas .nav{
      padding-top: 3.5rem;
      display: flex;
      justify-content: flex-end;
      padding-right: 1rem !important;
  }
}

@media (max-width: 991.98px) { 
  #tab-vermas .nav{
      font-size: .9rem;
      padding-top: 2rem;
  }
}

#tab-vermas .nav::after{
  content: '';
  background-color: #F9F9F9;
  position: absolute;
  z-index: 5;
  width: 100vw;
  height: 100vh;
  right: -2rem;
  bottom: 0;
  border-bottom: 1px solid #dee2e6;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link{
  color: var(--bg-primary) !important;
}

#modalVermas table{
  background-color: #fff;
}

#download-file{
  position: relative;
  z-index: 20;
}

#modalVermas table {
  background-color: #fff;
}

.disabledCursor { 
  pointer-events: none;
}

.dropdown-item.active, .dropdown-item:active{
  background-color: var(--bg-primary);
}

.list-group-item.active{
  background-color: var(--bg-primary);
  border-color: var(--bg-primary);
}

/* .btn-outline-primary{
  color: var(--bg-primary);
  border-color: var(--bg-primary);
}

.btn-outline-primary:active{
  background-color: var(--bg-primary);;
  border-color: var(--bg-primary);;
} */

#result-document-image > div{
  background-color: #fff;
}

.container-inner{
  padding-top: 1rem;
  padding-bottom: 1rem;
}
@media (min-width: 768px){
  .container-inner{
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
}

.breadcrumb-item{
  font-weight: 500;
}

.btn-for-icon{
  font-size: 1.3rem;
}

table thead{
  background-color: #eef1f5;
}

/* Arreglando Modals z-index */

.fade.modal-backdrop.show{
  z-index: 1060;  
}

.line-dashed{
  margin-top: .7rem;
  margin-bottom: .7rem;
  border-bottom: 1px dashed #707070;
}

.cursor-pointer{
  cursor: pointer;
}

.form-floating>label{
  opacity: .65;
}