/* ResultadoTest */

.ResultadoTest__header{
    width: 100%;
    padding: 1rem;
    border: 1px solid #ddd;
    background-color: #f9f9f9;
}

.ResultadoTest__footer{
    border: 1px solid #ddd;
    border-top: 0;
    padding: .3rem;
    text-align: center;
    background-color: #f9f9f9;
    font-weight: bold;
    cursor: pointer;
}

.ResultadoTest__iteminfo{
    flex: 1;
    text-align: center;
    display: table-cell;
}

.ResultadoTest__body{
    border: 1px solid #ddd;
    padding: 2rem !important;
    box-shadow: inset 0px 0px 4px #d3d3d3 !important;
    background: #f5f5f5 !important;
}

.ResultadoTest__TdLoader{
    box-shadow: inset 0px 0px 4px #d3d3d3 !important;
    background-color: #f5f5f5 !important;
}

.ResultadoTest__body table{
    background-color: #fff;
}

.tableMainHistorial > tbody > tr:nth-child(2n):hover {
    background-color: inherit;
}

.bg-tr-detail{
    background-color: #f5f5f5 !important;
    --bs-table-accent-bg: #f5f5f5 !important;
}
