#NavTop-legal{
  background-color: #FFF;
  /* height: 16rem; */
  border-bottom: 1px solid #e8e8e8;
  color: #333;
}

#NavTop-legal ul{
  list-style: none;
  padding: 0;
  margin: 0;
}

#NavTop-legal ul a{
  color: #333;
  text-decoration: none;
  font-size: .9rem;
  opacity: .7;
  margin-left: 1rem;
}

#NavTop-legal ul a.active,
#NavTop-legal ul a:hover{
  opacity: 1;
}

#NavTop-legal #logo{
  max-width: 270px;
}

#Main-legal{
  position: relative;
  padding-top: 2rem;
  background-color: #f2f4f6;
}


.Main-legal-content{
  background-color: #fff;
  padding: 2rem;
  /* padding-top: .5rem; */
  border-radius: .4rem .4rem 0 0;
}

.bg-opacity-primary{
  background: #ecf0f5;
}

.Main-legal-content h4{
  /* position: absolute; */
  top: -3.5rem; 
  /* color: #fff; */
  font-size: 1.7rem;
  font-weight: 700;
  left: 0;
  right: 0;
  margin-bottom: 2rem;
}

.Main-legal-content a{
  color: #4c4184;
  color: var(--bg-primary);
  /* color: #000; */
}


@media (max-width: 992px) {
  #Main-legal{
    /* top: -2rem; */
  }

  #NavTop-legal{
    /* height: 18rem; */
  }

  #NavTop-legal ul a{
    margin-left: .5rem;
    margin-right: .5rem;
  }

  .Main-legal-content{
    padding: 1rem;
  }
}
